import React from 'react';
import { Box, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const steps = [
  {
    icon: <EngineeringIcon />,
    label: 'Intervention du technicien',
    description: 'Nous intervenons chez vous à la date convenue lors de la prise de rendez-vous. Notre technicien expert évalue précisément vos besoins sur place.',
    color: '#4CAF50'
  },
  {
    icon: <AssignmentIcon />,
    label: 'Vérification des infrastructures',
    description: 'Nous vérifions la faisabilité de votre solution photovoltaïque et préparons la pose de vos panneaux solaires. Cette étape cruciale garantit une installation optimale.',
    color: '#FFD700'
  },
  {
    icon: <BuildIcon />,
    label: 'Installation du matériel',
    description: 'Vous recevez le matériel 24 à 72h avant la date du début du chantier. L\'installateur réalise le montage à la date convenue, en 1 journée, dans le respect des normes.',
    color: '#4CAF50'
  },
  {
    icon: <CheckCircleIcon />,
    label: 'Pilotage et suivi',
    description: 'Suivez votre taux de production et d\'autoconsommation directement sur votre espace client dédié. Visualisez vos économies en temps réel.',
    color: '#FFD700'
  }
];

const Process = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        py: 10,
        background: 'linear-gradient(135deg, #1b5e20 0%, #2e7d32 100%)',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
          animation: 'pulse 15s infinite',
        },
        '@keyframes pulse': {
          '0%': { transform: 'scale(1)' },
          '50%': { transform: 'scale(1.5)' },
          '100%': { transform: 'scale(1)' },
        }
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          sx={{
            mb: 8,
            fontWeight: 800,
            fontSize: { xs: '2rem', md: '3.5rem' },
            color: 'white',
            textShadow: '0 2px 4px rgba(0,0,0,0.3)',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -16,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 100,
              height: 4,
              background: 'linear-gradient(90deg, #4CAF50, #FFD700)',
              borderRadius: 2
            }
          }}
        >
          Comment se déroule l'installation de mes panneaux solaires ?
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              right: 0,
              top: isMobile ? '20px' : '50%',
              height: isMobile ? '100%' : '4px',
              width: isMobile ? '4px' : '100%',
              background: 'rgba(255,255,255,0.1)',
              transform: isMobile ? 'none' : 'translateY(-50%)',
              borderRadius: '4px',
            }
          }}
        >
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                flex: 1,
                position: 'relative',
                px: 2,
                mb: isMobile ? 4 : 0,
                opacity: 0,
                transform: 'translateY(20px)',
                animation: 'fadeInUp 0.5s ease forwards',
                animationDelay: `${index * 0.2}s`,
                '@keyframes fadeInUp': {
                  '0%': {
                    opacity: 0,
                    transform: 'translateY(20px)',
                  },
                  '100%': {
                    opacity: 1,
                    transform: 'translateY(0)',
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    width: 70,
                    height: 70,
                    borderRadius: '50%',
                    background: `linear-gradient(135deg, ${step.color} 0%, ${step.color}88 100%)`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '1.5rem',
                    boxShadow: `0 4px 20px ${step.color}66`,
                    mb: 3,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.1) rotate(360deg)',
                      boxShadow: `0 6px 30px ${step.color}88`,
                    },
                    '& svg': {
                      fontSize: '2rem',
                      transition: 'transform 0.3s ease',
                    },
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: -5,
                      left: -5,
                      right: -5,
                      bottom: -5,
                      background: `linear-gradient(135deg, ${step.color}22 0%, ${step.color}00 100%)`,
                      borderRadius: '50%',
                      animation: 'pulse 2s infinite',
                    }
                  }}
                >
                  {step.icon}
                </Box>

                <Box
                  sx={{
                    background: 'rgba(255,255,255,0.1)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: 3,
                    p: 3,
                    border: '1px solid rgba(255,255,255,0.1)',
                    transition: 'all 0.3s ease',
                    height: '100%',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      background: 'rgba(255,255,255,0.15)',
                      boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{
                      mb: 2,
                      fontWeight: 700,
                      color: 'white',
                      '&::before': {
                        content: '"0" attr(data-step)',
                        color: step.color,
                        marginRight: '8px',
                        fontWeight: 900,
                      }
                    }}
                    data-step={index + 1}
                  >
                    {step.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      color: 'rgba(255,255,255,0.9)',
                      lineHeight: 1.8,
                      fontSize: '1rem',
                    }}
                  >
                    {step.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Process;
