import React from 'react';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px !important',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.1)',
  marginBottom: '16px !important',
  transition: 'all 0.3s ease-in-out',
  overflow: 'hidden',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '16px 0 !important',
    boxShadow: '0 12px 48px 0 rgba(31, 38, 135, 0.15)',
    transform: 'scale(1.02)',
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  '& .MuiAccordionSummary-content': {
    margin: '12px 0',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
    transform: 'scale(1.2)',
    transition: 'all 0.3s ease-in-out',
  },
  '&.Mui-expanded': {
    background: `linear-gradient(135deg, ${theme.palette.primary.light}15, ${theme.palette.secondary.light}15)`,
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(180deg) scale(1.2)',
    }
  }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
  background: 'rgba(255, 255, 255, 0.5)',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  marginBottom: theme.spacing(8),
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-12px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '4px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    borderRadius: '2px',
  }
}));

const faqs = [
  {
    question: "Quelles aides financières sont disponibles ?",
    answer: "Plusieurs dispositifs sont accessibles : MaPrimeRénov', la prime à l'autoconsommation, l'éco-prêt à taux zéro (éco-PTZ), et la TVA à 10%. Notre équipe vous accompagne dans l'obtention de ces aides qui peuvent couvrir jusqu'à 75% de votre investissement."
  },
  {
    question: "Combien de temps dure l'installation ?",
    answer: "L'installation complète prend généralement 1 à 2 jours pour une maison individuelle. Nous planifions les travaux en fonction de vos disponibilités et nous assurons une mise en service rapide pour que vous puissiez profiter de votre installation au plus vite."
  },
  {
    question: "Quelle est la durée de vie des panneaux solaires ?",
    answer: "Nos panneaux solaires sont garantis 25 ans avec un rendement minimum de 80% après cette période. Leur durée de vie effective peut dépasser 30 ans avec un entretien approprié. Nous utilisons exclusivement des panneaux de marques premium pour assurer cette longévité."
  },
  {
    question: "Quel entretien est nécessaire ?",
    answer: "L'entretien est minimal : un nettoyage annuel et une inspection technique tous les 2 ans suffisent. Notre contrat de maintenance inclut ces interventions, le monitoring en temps réel de votre production, et une assistance technique 7j/7 pour une tranquillité totale."
  },
  {
    question: "Quelles économies puis-je espérer ?",
    answer: "Les économies varient selon votre consommation et l'ensoleillement, mais nos clients réalisent en moyenne 50-70% d'économies sur leur facture d'électricité. Nous effectuons une simulation précise basée sur votre profil de consommation pour vous donner une estimation personnalisée."
  }
];

function FAQ() {
  return (
    <Box 
      sx={{ 
        py: 12,
        background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
        position: 'relative',
        overflow: 'hidden',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `radial-gradient(${theme => theme.palette.primary.light}15 1px, transparent 1px)`,
          backgroundSize: '20px 20px',
          opacity: 0.5,
        }
      }}
    >
      <Container maxWidth="lg">
        <StyledTitle
          variant="h2"
          align="center"
          sx={{
            fontWeight: 700,
            fontSize: { xs: '2rem', md: '2.5rem' },
            color: 'text.primary'
          }}
        >
          Questions Fréquentes
        </StyledTitle>
        <Box sx={{ maxWidth: 800, margin: '0 auto' }}>
          {faqs.map((faq, index) => (
            <StyledAccordion key={index}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: 'text.primary',
                    fontWeight: 600,
                    fontSize: { xs: '1rem', md: '1.125rem' }
                  }}
                >
                  {faq.question}
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography 
                  sx={{ 
                    color: 'text.secondary',
                    lineHeight: 1.8,
                    fontSize: '1rem'
                  }}
                >
                  {faq.answer}
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default FAQ;
