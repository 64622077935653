import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent, Rating, Avatar } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import StarIcon from '@mui/icons-material/Star';

const reviews = [
  {
    name: 'Marie Dubois',
    location: 'Bordeaux',
    rating: 5,
    date: '15 juillet 2023',
    text: 'Installation impeccable, équipe très professionnelle. La production est même supérieure aux estimations. Je recommande vivement !',
    platform: 'google',
    avatar: 'https://i.pravatar.cc/150?img=1',
    verified: true
  },
  {
    name: 'Thomas Martin',
    location: 'Lyon',
    rating: 5,
    date: '2 août 2023',
    text: 'Excellente expérience avec cette entreprise. Le suivi est parfait et les économies sont au rendez-vous. Un grand merci à toute l\'équipe.',
    platform: 'trustpilot',
    avatar: 'https://i.pravatar.cc/150?img=3',
    verified: true
  },
  {
    name: 'Sophie Bernard',
    location: 'Toulouse',
    rating: 4,
    date: '28 juillet 2023',
    text: 'De l\'étude à l\'installation, tout s\'est déroulé comme prévu. Les panneaux sont discrets et performants. Je suis très satisfaite !',
    platform: 'facebook',
    avatar: 'https://i.pravatar.cc/150?img=5',
    verified: true
  },
  {
    name: 'Laurent Petit',
    location: 'Nantes',
    rating: 5,
    date: '20 juillet 2023',
    text: 'Service client au top, installation soignée. Mes factures d\'électricité ont significativement baissé. Un investissement rentable !',
    platform: 'avis-verifies',
    avatar: 'https://i.pravatar.cc/150?img=7',
    verified: true
  },
  {
    name: 'Philippe Moreau',
    location: 'Marseille',
    rating: 5,
    date: '10 juillet 2023',
    text: 'Très satisfait de mon installation. L\'équipe est à l\'écoute et réactive. La qualité du travail est irréprochable.',
    platform: 'google',
    avatar: 'https://i.pravatar.cc/150?img=9',
    verified: true
  },
  {
    name: 'Claire Rousseau',
    location: 'Nice',
    rating: 5,
    date: '5 juillet 2023',
    text: 'Un grand professionnalisme du début à la fin. Installation rapide et soignée. Je produis maintenant ma propre électricité !',
    platform: 'trustpilot',
    avatar: 'https://i.pravatar.cc/150?img=11',
    verified: true
  }
];

const platformRatings = {
  google: 4.72,
  facebook: 4.56,
  trustpilot: 4.68,
  'avis-verifies': 4.43
};

const platformConfigs = {
  google: {
    label: 'Google',
    color: '#4285F4',
    background: '#ffffff',
    border: '1px solid #e0e0e0'
  },
  facebook: {
    label: 'Facebook',
    color: '#1877F2',
    background: '#ffffff',
    border: '1px solid #e0e0e0'
  },
  trustpilot: {
    label: 'Trustpilot',
    color: '#00B67A',
    background: '#ffffff',
    border: '1px solid #e0e0e0'
  },
  'avis-verifies': {
    label: 'Avis Vérifiés',
    color: '#24b9d7',
    background: '#ffffff',
    border: '1px solid #e0e0e0'
  }
};

const getPlatformIcon = (platform) => {
  switch (platform) {
    case 'google':
      return <GoogleIcon />;
    case 'facebook':
      return <FacebookIcon />;
    case 'trustpilot':
      return <StarIcon />;
    case 'avis-verifies':
      return <VerifiedUserIcon />;
    default:
      return null;
  }
};

const Testimonials = () => {
  return (
    <Box
      sx={{
        py: 8,
        background: 'linear-gradient(135deg, #f8faf8 0%, #ffffff 100%)',
      }}
    >
      <Container>
        <Typography
          variant="h2"
          align="center"
          sx={{
            mb: 1,
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '3rem' },
            background: 'linear-gradient(135deg, #4CAF50 0%, #FFD700 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            color: 'transparent'
          }}
        >
          Ils nous font confiance
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mb: 6,
            flexWrap: 'wrap'
          }}
        >
          {Object.entries(platformConfigs).map(([key, config]) => (
            <Box
              key={key}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                px: 2,
                py: 1,
                borderRadius: 2,
                background: config.background,
                border: config.border
              }}
            >
              <Box sx={{ color: config.color }}>
                {getPlatformIcon(key)}
              </Box>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 'medium',
                    color: config.color,
                    display: 'block'
                  }}
                >
                  {config.label}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Rating
                    value={platformRatings[key]}
                    readOnly
                    precision={0.01}
                    size="small"
                    sx={{ color: config.color }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 'bold',
                      color: config.color
                    }}
                  >
                    {platformRatings[key].toFixed(2)}/5
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Grid container spacing={3}>
          {reviews.map((review, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                  }
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      src={review.avatar}
                      sx={{ 
                        width: 48, 
                        height: 48, 
                        mr: 2,
                        border: '2px solid',
                        borderColor: platformConfigs[review.platform].color
                      }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {review.name}
                        </Typography>
                        {review.verified && (
                          <VerifiedUserIcon
                            sx={{
                              fontSize: 16,
                              color: platformConfigs[review.platform].color
                            }}
                          />
                        )}
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {review.location}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 1,
                        background: platformConfigs[review.platform].background,
                        border: platformConfigs[review.platform].border
                      }}
                    >
                      <Box sx={{ color: platformConfigs[review.platform].color }}>
                        {getPlatformIcon(review.platform)}
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Rating
                      value={review.rating}
                      readOnly
                      size="small"
                      sx={{
                        color: platformConfigs[review.platform].color,
                        mr: 1
                      }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      {review.date}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body1"
                    sx={{
                      color: 'text.secondary',
                      fontStyle: 'italic',
                      position: 'relative',
                      pl: 3,
                      pr: 3
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        fontSize: '1.5em',
                        color: 'rgba(0,0,0,0.1)',
                      }}
                    >
                      "
                    </Box>
                    {review.text}
                    <Box
                      component="span"
                      sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        fontSize: '1.5em',
                        color: 'rgba(0,0,0,0.1)',
                      }}
                    >
                      "
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Testimonials;
