import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  ListSubheader,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ManIcon from '@mui/icons-material/Man2';
import WomanIcon from '@mui/icons-material/Woman2';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HomeIcon from '@mui/icons-material/Home';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: theme.spacing(1),
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  textTransform: 'none',
  minWidth: 100,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
}));

const MenuItemStyles = {
  display: 'flex !important',
  alignItems: 'center',
  gap: 1.5,
  py: 1,
  px: 2,
  '& .icon': {
    display: 'flex',
    alignItems: 'center',
    color: theme => theme.palette.primary.main,
    fontSize: '1.2rem',
  },
  '& .image': {
    width: 28,
    height: 28,
    objectFit: 'cover',
    borderRadius: 1,
  },
  '&:hover': {
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(76, 175, 80, 0.16) !important',
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.24) !important',
    },
  },
};

const SelectDisplayStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1.5,
  '& .icon': {
    color: theme => theme.palette.primary.main,
    fontSize: '1.2rem',
  },
  '& .image': {
    width: 28,
    height: 28,
    objectFit: 'cover',
    borderRadius: 1,
  },
};

const heatingTypes = [
  { value: 'Électrique', label: 'Électrique', icon: <ElectricBoltIcon /> },
  { value: 'Gaz', label: 'Gaz', icon: <FireplaceIcon /> },
  { value: 'Fioul', label: 'Fioul', icon: <WaterDropIcon /> },
  { value: 'Pompe à chaleur', label: 'Pompe à chaleur', icon: <AcUnitIcon /> },
  { value: 'Autre', label: 'Autre', icon: <HomeIcon /> }
];

const roofTypes = [
  { 
    value: 'Tuiles', 
    label: 'Tuiles', 
    image: './assets/images/roofs/tuiles.svg'
  },
  { 
    value: 'Ardoises', 
    label: 'Ardoises', 
    image: './assets/images/roofs/ardoises.svg'
  },
  { 
    value: 'Toit plat', 
    label: 'Toit plat', 
    image: './assets/images/roofs/toit-plat.svg'
  },
  { 
    value: 'Autre', 
    label: 'Autre', 
    image: './assets/images/roofs/autre.svg'
  }
];

const departmentsByRegion = {
  'Île-de-France': [
    "75 - Paris", "77 - Seine-et-Marne", "78 - Yvelines", "91 - Essonne",
    "92 - Hauts-de-Seine", "93 - Seine-Saint-Denis", "94 - Val-de-Marne", "95 - Val-d'Oise"
  ],
  'Auvergne-Rhône-Alpes': [
    "01 - Ain", "03 - Allier", "07 - Ardèche", "15 - Cantal", "26 - Drôme",
    "38 - Isère", "42 - Loire", "43 - Haute-Loire", "63 - Puy-de-Dôme",
    "69 - Rhône", "73 - Savoie", "74 - Haute-Savoie"
  ],
  'Bourgogne-Franche-Comté': [
    "21 - Côte-d'Or", "25 - Doubs", "39 - Jura", "58 - Nièvre",
    "70 - Haute-Saône", "71 - Saône-et-Loire", "89 - Yonne", "90 - Territoire de Belfort"
  ],
  'Bretagne': [
    "22 - Côtes-d'Armor", "29 - Finistère", "35 - Ille-et-Vilaine", "56 - Morbihan"
  ],
  'Centre-Val de Loire': [
    "18 - Cher", "28 - Eure-et-Loir", "36 - Indre", "37 - Indre-et-Loire",
    "41 - Loir-et-Cher", "45 - Loiret"
  ],
  'Corse': [
    "2A - Corse-du-Sud", "2B - Haute-Corse"
  ],
  'Grand Est': [
    "08 - Ardennes", "10 - Aube", "51 - Marne", "52 - Haute-Marne",
    "54 - Meurthe-et-Moselle", "55 - Meuse", "57 - Moselle", "67 - Bas-Rhin",
    "68 - Haut-Rhin", "88 - Vosges"
  ],
  'Hauts-de-France': [
    "02 - Aisne", "59 - Nord", "60 - Oise", "62 - Pas-de-Calais", "80 - Somme"
  ],
  'Normandie': [
    "14 - Calvados", "27 - Eure", "50 - Manche", "61 - Orne", "76 - Seine-Maritime"
  ],
  'Nouvelle-Aquitaine': [
    "16 - Charente", "17 - Charente-Maritime", "19 - Corrèze", "23 - Creuse",
    "24 - Dordogne", "33 - Gironde", "40 - Landes", "47 - Lot-et-Garonne",
    "64 - Pyrénées-Atlantiques", "79 - Deux-Sèvres", "86 - Vienne", "87 - Haute-Vienne"
  ],
  'Occitanie': [
    "09 - Ariège", "11 - Aude", "12 - Aveyron", "30 - Gard", "31 - Haute-Garonne",
    "32 - Gers", "34 - Hérault", "46 - Lot", "48 - Lozère", "65 - Hautes-Pyrénées",
    "66 - Pyrénées-Orientales", "81 - Tarn", "82 - Tarn-et-Garonne"
  ],
  'Pays de la Loire': [
    "44 - Loire-Atlantique", "49 - Maine-et-Loire", "53 - Mayenne",
    "72 - Sarthe", "85 - Vendée"
  ],
  'Provence-Alpes-Côte d\'Azur': [
    "04 - Alpes-de-Haute-Provence", "05 - Hautes-Alpes", "06 - Alpes-Maritimes",
    "13 - Bouches-du-Rhône", "83 - Var", "84 - Vaucluse"
  ]
};

export default function QuoteForm({ onSubmit, isSubmitting, variant = 'dialog' }) {
  const initialFormData = {
    civilite: '',
    firstName: '',
    lastName: '',
    phone: '',
    department: '',
    heatingType: '',
    roofType: '',
    message: ''
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [submitStatus, setSubmitStatus] = React.useState({ success: false, message: '' });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCiviliteChange = (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      civilite: newValue
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitStatus({ success: false, message: '' });
    try {
      await onSubmit({
        civilite: formData.civilite,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        department: formData.department,
        heatingType: formData.heatingType,
        roofType: formData.roofType,
        message: formData.message,
        source: variant === 'hero' ? 'Website Hero Form' : 'Website Dialog Form',
        submissionDate: new Date().toLocaleString('fr-FR', {
          dateStyle: 'full',
          timeStyle: 'medium',
          timeZone: 'Europe/Paris'
        })
      });
      setSubmitStatus({ 
        success: true, 
        message: 'Merci pour votre confiance. Notre équipe d\'experts vous contactera dans les plus brefs délais.' 
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus({ 
        success: false, 
        message: 'Une erreur est survenue lors de l\'envoi de votre demande.' 
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        {/* Civilité */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <span>Je suis</span>
            <StyledToggleButtonGroup
              value={formData.civilite}
              exclusive
              onChange={handleCiviliteChange}
              aria-label="civilité"
            >
              <StyledToggleButton value="Monsieur" aria-label="monsieur">
                <ManIcon sx={{ mr: 1 }} />
                Monsieur
              </StyledToggleButton>
              <StyledToggleButton value="Madame" aria-label="madame">
                <WomanIcon sx={{ mr: 1 }} />
                Madame
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          </Box>
        </Grid>

        {/* Nom et Prénom */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Nom"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Prénom"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>

        {/* Téléphone */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Téléphone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>

        {/* Département */}
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="department-label">Département</InputLabel>
            <StyledSelect
              labelId="department-label"
              id="department"
              name="department"
              value={formData.department}
              label="Département"
              onChange={handleChange}
              disabled={isSubmitting}
            >
              {Object.entries(departmentsByRegion).map(([region, departments]) => [
                <ListSubheader key={region} sx={{
                  backgroundColor: 'white',
                  color: 'primary.main',
                  fontWeight: 'bold',
                  lineHeight: '32px'
                }}>
                  {region}
                </ListSubheader>,
                ...departments.map(dept => (
                  <MenuItem 
                    key={dept} 
                    value={dept}
                    sx={MenuItemStyles}
                  >
                    {dept}
                  </MenuItem>
                ))
              ]).flat()}
            </StyledSelect>
          </FormControl>
        </Grid>

        {/* Type de chauffage et toiture */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id="heatingType-label">Type de chauffage</InputLabel>
            <StyledSelect
              labelId="heatingType-label"
              id="heatingType"
              name="heatingType"
              value={formData.heatingType}
              label="Type de chauffage"
              onChange={handleChange}
              disabled={isSubmitting}
              renderValue={(value) => {
                const type = heatingTypes.find(t => t.value === value);
                return (
                  <Box sx={SelectDisplayStyles}>
                    {React.cloneElement(type.icon, { className: 'icon' })}
                    {type.label}
                  </Box>
                );
              }}
            >
              {heatingTypes.map((type) => (
                <MenuItem 
                  key={type.value} 
                  value={type.value}
                  sx={MenuItemStyles}
                >
                  {React.cloneElement(type.icon, { className: 'icon' })}
                  {type.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id="roofType-label">Type de toiture</InputLabel>
            <StyledSelect
              labelId="roofType-label"
              id="roofType"
              name="roofType"
              value={formData.roofType}
              label="Type de toiture"
              onChange={handleChange}
              disabled={isSubmitting}
              renderValue={(value) => {
                const type = roofTypes.find(t => t.value === value);
                return (
                  <Box sx={SelectDisplayStyles}>
                    <Box
                      component="img"
                      src={type.image}
                      alt={type.label}
                      className="image"
                    />
                    {type.label}
                  </Box>
                );
              }}
            >
              {roofTypes.map((type) => (
                <MenuItem 
                  key={type.value} 
                  value={type.value}
                  sx={MenuItemStyles}
                >
                  <Box
                    component="img"
                    src={type.image}
                    alt={type.label}
                    className="image"
                  />
                  {type.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>

        {/* Message */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Message (optionnel)"
            name="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>

        {/* Message de statut */}
        {submitStatus.message && (
          <Grid item xs={12}>
            <Alert 
              severity={submitStatus.success ? "success" : "error"}
              sx={{ 
                mt: 2,
                '& .MuiAlert-message': {
                  width: '100%',
                  textAlign: 'center'
                }
              }}
            >
              {submitStatus.message}
            </Alert>
          </Grid>
        )}

        {/* Bouton de soumission */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            sx={{
              background: theme => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
              color: 'white',
              padding: '10px 30px',
              '&:hover': {
                background: theme => `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
              },
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Envoyer"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
