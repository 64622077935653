import React from 'react';
import { Box } from '@mui/material';
import Header from './components/Header';
import Hero from './components/Hero';
import Presentation from './components/Presentation';
import Testimonials from './components/Testimonials';
import Advantages from './components/Advantages';
import Process from './components/Process';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

function App() {
  return (
    <Box>
      <Header />
      <Box 
        id="hero"
        sx={{ 
          pt: { xs: '64px', md: '80px' } 
        }}
      >
        <Hero />
      </Box>
      <Box id="presentation">
        <Presentation />
      </Box>
      <Box id="advantages">
        <Advantages />
      </Box>
      <Box id="testimonials">
        <Testimonials />
      </Box>
      <Box id="process">
        <Process />
      </Box>
      <Box id="faq">
        <FAQ />
      </Box>
      <Box id="contact">
        <Footer />
      </Box>
    </Box>
  );
}

export default App;
