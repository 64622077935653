import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuoteDialog from './QuoteDialog';
import { Facebook, Instagram, LinkedIn, WhatsApp } from '@mui/icons-material';

function Footer() {
  const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);

  const handleQuoteClick = () => {
    setQuoteDialogOpen(true);
  };

  const handleQuoteClose = () => {
    setQuoteDialogOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#1b5e20',
        color: 'white',
        pt: 8,
        pb: 4,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: 'linear-gradient(90deg, #4CAF50, #FFD700)',
        }
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 700,
                  mb: 2,
                  background: 'linear-gradient(90deg, #4CAF50, #FFD700)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  cursor: 'pointer'
                }}
                onClick={() => scrollToSection('hero')}
              >
                Solisphere
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.8 }}>
                Leader dans l'installation de panneaux solaires, nous nous engageons à rendre l'énergie solaire accessible à tous tout en contribuant à un avenir plus durable.
              </Typography>
            </Box>
          </Grid>

          {/* Services Links */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
              Nos Services
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Link 
                  component="button"
                  onClick={() => scrollToSection('services')}
                  color="inherit" 
                  underline="none"
                  sx={{
                    display: 'block',
                    mb: 1.5,
                    '&:hover': {
                      color: '#FFD700',
                      transform: 'translateX(5px)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Installation Solaire
                </Link>
                <Link 
                  component="button"
                  onClick={() => scrollToSection('services')}
                  color="inherit" 
                  underline="none"
                  sx={{
                    display: 'block',
                    mb: 1.5,
                    '&:hover': {
                      color: '#FFD700',
                      transform: 'translateX(5px)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Maintenance
                </Link>
                <Link 
                  component="button"
                  onClick={() => scrollToSection('advantages')}
                  color="inherit" 
                  underline="none"
                  sx={{
                    display: 'block',
                    mb: 1.5,
                    '&:hover': {
                      color: '#FFD700',
                      transform: 'translateX(5px)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Conseil Énergétique
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link 
                  component="button"
                  onClick={handleQuoteClick}
                  color="inherit" 
                  underline="none"
                  sx={{
                    display: 'block',
                    mb: 1.5,
                    '&:hover': {
                      color: '#FFD700',
                      transform: 'translateX(5px)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Devis Gratuit
                </Link>
                <Link 
                  component="button"
                  onClick={() => scrollToSection('faq')}
                  color="inherit" 
                  underline="none"
                  sx={{
                    display: 'block',
                    mb: 1.5,
                    '&:hover': {
                      color: '#FFD700',
                      transform: 'translateX(5px)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  FAQ
                </Link>
                <Link 
                  component="button"
                  onClick={() => scrollToSection('testimonials')}
                  color="inherit" 
                  underline="none"
                  sx={{
                    display: 'block',
                    mb: 1.5,
                    '&:hover': {
                      color: '#FFD700',
                      transform: 'translateX(5px)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Témoignages
                </Link>
              </Grid>
            </Grid>
          </Grid>

          {/* Contact Info */}

        </Grid>

        {/* Copyright */}
        <Box sx={{ mt: 8, pt: 3, borderTop: '1px solid rgba(255, 255, 255, 0.1)', textAlign: 'center' }}>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            {new Date().getFullYear()} Solisphere. Tous droits réservés.
          </Typography>
        </Box>

        {/* WhatsApp Floating Button */}
        <Box
          component="a"
          href="https://wa.me/330651518009"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            width: 60,
            height: 60,
            borderRadius: '50%',
            backgroundColor: '#25D366',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
            cursor: 'pointer',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.1)',
              backgroundColor: '#20BA5C'
            },
            zIndex: 1000
          }}
        >
          <WhatsApp sx={{ color: 'white', fontSize: 30 }} />
        </Box>
      </Container>
      <QuoteDialog open={quoteDialogOpen} onClose={handleQuoteClose} />
    </Box>
  );
}

export default Footer;
