import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Paper,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import QuoteForm from './QuoteForm';

const GradientButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  '&:hover': {
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
}));

function Hero() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async (formData) => {
    setIsSubmitting(true);
    try {
      await fetch('https://hooks.zapier.com/hooks/catch/2393504/2ijzeux/', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          source: 'Website Hero Form',
          submissionDate: new Date().toLocaleString('fr-FR', {
            dateStyle: 'full',
            timeStyle: 'medium',
            timeZone: 'Europe/Paris'
          })
        }),
      });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        overflow: 'hidden',
        pt: 8,
        pb: 6,
      }}
    >
      {/* Video Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4))',
          },
        }}
      >
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src="./assets/videos/solar-drone.mp4" type="video/mp4" />
        </video>
      </Box>

      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          {/* Left side - Text content */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h2" 
                component="h1" 
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                }}
              >
                Installez vos panneaux solaires et réalisez des économies dès aujourd'hui !
              </Typography>
              <Typography 
                variant="h5" 
                paragraph
                sx={{
                  mb: 4,
                  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                }}
              >
                Profitez des subventions pour une transition énergétique rentable et contribuez à un avenir plus vert.
              </Typography>
            </Box>
          </Grid>

          {/* Right side - Form */}
          <Grid item xs={12} md={6}>
            <StyledPaper>
              <Typography 
                variant="h5" 
                component="h2" 
                gutterBottom 
                color="primary"
                sx={{
                  fontWeight: 600,
                  textAlign: 'center',
                  mb: 3,
                  background: theme => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                }}
              >
                Obtenez votre devis gratuit
              </Typography>
              <QuoteForm 
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                variant="hero"
              />
            </StyledPaper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Hero;
