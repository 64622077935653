import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import QuoteForm from './QuoteForm';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    },
  },
}));

const DialogTitleStyled = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
});

const GradientTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.5rem',
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  display: 'inline-block',
}));

function QuoteDialog({ open, onClose }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async (formData) => {
    setIsSubmitting(true);
    try {
      await fetch('https://hooks.zapier.com/hooks/catch/2393504/2ijzeux/', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          source: 'Website Dialog Form',
          submissionDate: new Date().toLocaleString('fr-FR', {
            dateStyle: 'full',
            timeStyle: 'medium',
            timeZone: 'Europe/Paris'
          })
        }),
      });
      onClose();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={!isSubmitting ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <DialogTitleStyled>
        <GradientTitle>
          Demandez un devis gratuit
        </GradientTitle>
        {!isSubmitting && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitleStyled>
      <DialogContent>
        <QuoteForm
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          variant="dialog"
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default QuoteDialog;