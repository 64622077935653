import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Container, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import QuoteDialog from './QuoteDialog';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.95)',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: '15px 0',
  [theme.breakpoints.down('md')]: {
    padding: '10px 0',
  },
}));

const Logo = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 700,
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  fontSize: '1.5rem',
  display: 'inline-block',
  cursor: 'pointer',
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  '&:hover': {
    color: theme.palette.primary.main,
    background: 'rgba(0, 0, 0, 0.04)',
  },
}));

const CtaButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  color: 'white',
  padding: '10px 25px',
  '&:hover': {
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
  },
}));

const MobileNav = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const DesktopNav = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

function Header() {
  const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    setMobileMenuOpen(false);
  };

  const handleQuoteClick = () => {
    setQuoteDialogOpen(true);
    setMobileMenuOpen(false);
  };

  const handleQuoteClose = () => {
    setQuoteDialogOpen(false);
  };

  const menuItems = [
    { text: 'Présentation', section: 'presentation' },
    { text: 'Avantages', section: 'advantages' },
    { text: 'Témoignages', section: 'testimonials' },
    { text: 'Installation', section: 'process' },
    { text: 'FAQ', section: 'faq' },
  ];

  return (
    <>
      <StyledAppBar>
        <Container maxWidth="lg">
          <StyledToolbar>
            <Logo onClick={() => scrollToSection('hero')}>
              Solisphere
            </Logo>
            
            <DesktopNav>
              {menuItems.map((item) => (
                <NavButton 
                  key={item.section}
                  onClick={() => scrollToSection(item.section)}
                >
                  {item.text}
                </NavButton>
              ))}
              <CtaButton 
                variant="contained"
                onClick={handleQuoteClick}
              >
                Devis gratuit
              </CtaButton>
            </DesktopNav>

            <MobileNav>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </MobileNav>
          </StyledToolbar>
        </Container>
      </StyledAppBar>

      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: '250px',
            background: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <List sx={{ pt: 2 }}>
          {menuItems.map((item) => (
            <ListItem 
              key={item.section}
              onClick={() => scrollToSection(item.section)}
              sx={{ 
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(76, 175, 80, 0.08)',
                },
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          <ListItem sx={{ mt: 2 }}>
            <CtaButton 
              fullWidth
              variant="contained"
              onClick={handleQuoteClick}
            >
              Devis gratuit
            </CtaButton>
          </ListItem>
        </List>
      </Drawer>

      <QuoteDialog 
        open={quoteDialogOpen}
        onClose={handleQuoteClose}
      />
    </>
  );
}

export default Header;
