import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent } from '@mui/material';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SavingsIcon from '@mui/icons-material/Savings';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import EuroIcon from '@mui/icons-material/Euro';

const advantagesData = [
  {
    icon: <SolarPowerIcon sx={{ fontSize: 40 }} />,
    value: 75,
    suffix: '%',
    title: "d'économies d'énergie",
    description: "Réduisez votre consommation d'énergie"
  },
  {
    icon: <SavingsIcon sx={{ fontSize: 40 }} />,
    value: 40,
    suffix: '%',
    title: 'de retour sur investissement',
    description: 'Un investissement rentable à long terme'
  },
  {
    icon: <VolunteerActivismIcon sx={{ fontSize: 40 }} />,
    value: 25,
    suffix: 'ans',
    title: 'de garantie',
    description: 'Une installation durable et fiable'
  },
  {
    icon: <EuroIcon sx={{ fontSize: 40 }} />,
    value: 9000,
    suffix: '€',
    title: "d'aides de l'État",
    description: 'Des subventions gouvernementales importantes'
  }
];

const Advantages = () => {
  return (
    <Box
      sx={{
        py: 8,
        background: 'linear-gradient(135deg, #f8faf8 0%, #ffffff 100%)',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(135deg, rgba(76, 175, 80, 0.05) 0%, rgba(255, 215, 0, 0.05) 100%)',
          zIndex: 1
        }
      }}
    >
      <Container sx={{ position: 'relative', zIndex: 2 }}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            mb: 6,
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '3rem' },
            background: 'linear-gradient(135deg, #4CAF50 0%, #FFD700 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            color: 'transparent'
          }}
        >
          Les avantages en chiffres
        </Typography>
        
        <Grid container spacing={4}>
          {advantagesData.map((advantage, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  background: '#ffffff',
                  borderRadius: 4,
                  transition: 'transform 0.3s ease-in-out',
                  border: '1px solid rgba(76, 175, 80, 0.1)',
                  boxShadow: '0 4px 20px rgba(76, 175, 80, 0.05)',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 8px 30px rgba(76, 175, 80, 0.1)',
                  }
                }}
              >
                <CardContent
                  sx={{
                    textAlign: 'center',
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-flex',
                      p: 2,
                      borderRadius: '50%',
                      background: 'linear-gradient(135deg, #4CAF50 0%, #FFD700 100%)',
                      mb: 2,
                      color: 'white'
                    }}
                  >
                    {advantage.icon}
                  </Box>
                  
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 'bold',
                          mb: 2,
                          fontSize: { xs: '2rem', md: '2.5rem' },
                          background: 'linear-gradient(135deg, #4CAF50 0%, #FFD700 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          backgroundClip: 'text',
                          color: 'transparent'
                        }}
                      >
                        {advantage.prefix}
                        <CountUp
                          start={0}
                          end={isVisible ? advantage.value : 0}
                          duration={2.5}
                          separator=" "
                        />
                        {advantage.suffix}
                      </Typography>
                    )}
                  </VisibilitySensor>

                  <Typography
                    variant="h6"
                    sx={{
                      mb: 1,
                      fontWeight: 'medium',
                      color: '#2E7D32'
                    }}
                  >
                    {advantage.title}
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      color: 'text.secondary'
                    }}
                  >
                    {advantage.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Advantages;
